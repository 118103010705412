export const deviceTypesAndProducers = {
    Laptop: ["Acer", "Apple", "Asus", "Dell", "HP", "Lenovo", "MSI", "Samsung", "Sony", "Toshiba", "Xiaomi"],
    "Komputer Stacjonarny": [],
    Smartfon: ["Apple", "Google", "Huawei", "Lenovo", "LG", "Meizu", "Oneplus", "OPPO", "Samsung", "Xiaomi"],
    Tablet: ["Apple", "Huawei", "Lenovo", "Samsung", "Xiaomi"],
    Inne: [],
};
export const deviceProducers = [
    "Apple",
    "Acer",
    "Asus",
    "MSI",
    "Lenovo",
    "Samsung",
    "Sony",
    "Toshiba",
    "Xiaomi",
    "HP",
    "Dell",
    "AsRock",
    "Gigabyte",
    "Oneplus",
    "Huawei",
    "Google",
    "LG",
    "Meizu",
    "Oppo",
];

export const deviceСondition = ["Nowy", "Dobry", "Rysy, przetarcia", "Uszkodzona obudowa", "Zniszczony"];
export const deviceMalfunction = ["Nie włącza się", "Uszkodzone gniazdo", "Uszkodzony wyświetlacz", "Problem z oprogramowaniem"];
