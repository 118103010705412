const ordersIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 81.159 81.159"
        style={{
            enableBackground: "new 0 0 81.159 81.159",
        }}
        xmlSpace="preserve"
        {...props}>
        <path d="M74.175 5.262c-1.068-1.069-2.527-1.659-4.107-1.659-1.775 0-3.563.748-4.934 2.058l-5.884 4.776-.229.208c-1.301 1.299-1.813 3.124-1.498 4.962L43.806 28.926l-2.302-1.992c1.65-6.453 1.447-15.049-4.118-20.614C33.311 2.245 28.097 0 22.706 0a19.19 19.19 0 0 0-6.296 1.063 2.999 2.999 0 0 0-1.319 4.76l7.393 8.836-.364 5.683-6.144.701-8.771-7.338a3.002 3.002 0 0 0-4.761 1.32c-2.396 6.919-.285 14.371 5.792 20.444 3.274 3.276 7.988 5.007 13.63 5.007.413 0 .829-.021 1.245-.041-.209.182-.419.362-.617.56L5.641 57.847c-4.679 4.679-4.679 12.291 0 16.97l2.827 2.827c2.266 2.267 5.28 3.515 8.485 3.515s6.219-1.248 8.485-3.515L42.29 60.791a11.684 11.684 0 0 0 2.223-3.103l14.969 17.296c.048.055.098.107.148.159 1.678 1.677 4.196 2.601 7.094 2.601 3.521 0 7.095-1.385 9.326-3.615 3.922-3.922 5.158-12.267 1.012-16.416-.053-.051-.104-.101-.159-.146L55.108 38.709 67.682 25.76c.346.059.692.088 1.041.088 1.506 0 2.896-.563 3.916-1.582l4.984-6.113c1.141-1.192 1.855-2.695 2.021-4.251.193-1.822-.398-3.568-1.621-4.79l-3.848-3.85zM38.048 56.549 21.196 73.402c-1.133 1.133-2.64 1.757-4.243 1.757s-3.109-.624-4.243-1.757l-2.827-2.827a6.006 6.006 0 0 1 0-8.484l16.853-16.854a5.96 5.96 0 0 1 5.621-1.592l7.438 8.595a5.975 5.975 0 0 1-1.747 4.309zm33.758 13.338c-1.111 1.111-3.154 1.857-5.084 1.857-1.211 0-2.246-.293-2.793-.789L32.121 34.203a2.999 2.999 0 0 0-3.225-.88 22.306 22.306 0 0 1-7.031 1.152c-3.958 0-7.292-1.154-9.387-3.25-1.896-1.896-4.559-5.25-4.957-9.432l5.59 4.677c.63.528 1.447.773 2.266.68l9.915-1.132a2.999 2.999 0 0 0 2.653-2.789l.601-9.384a2.998 2.998 0 0 0-.693-2.116l-4.789-5.724c3.663.1 7.229 1.709 10.077 4.557 3.918 3.919 3.833 11.259 2.095 16.421a3 3 0 0 0 .88 3.226l36.757 31.808c1.333 1.459 1.018 5.784-1.067 7.87zm1.455-55.852-4.707 5.772a1.072 1.072 0 0 1-.164-.081l-2.037-1.21-15.787 16.26-2.207-1.909 16.41-15.935-1.209-2.035a1.105 1.105 0 0 1-.08-.165l5.541-4.499.23-.208c.299-.297.598-.4.784-.417l3.646 3.646c-.012.15-.109.469-.42.781z" />
    </svg>
);

export default ordersIcon;
