import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../../firebase";
import Button from "../../UI/button/Button";
import classes from "./FinishOrderModal.module.scss";

const FinishOrderModal = ({ onClose, firebaseId, order }) => {
    const handleSubmit = async () => {
        const orderRef = doc(db, "orders", firebaseId);
        await updateDoc(orderRef, {
            techData: {
                techDate: Date.now(),
                isAnyPayments: order.techData.isAnyPayments,
            },
        });
        onClose();
    };

    const repairCost = order.payments.reduce((acc, value) => acc + value.repairCost, 0);
    const repairPrice = order.payments.reduce((acc, value) => acc + value.repairPrice, 0);
    const repairIncome = order.payments.reduce((acc, value) => acc + (value.repairPrice - value.repairCost), 0);

    const { clientInfo, deviceInfo } = order;
    return (
        <div className={classes.modal}>
            <h1 className={classes.modal__title}>Завершити замовлення</h1>
            <div className={classes.modal__body}>
                <div className={classes.modal__row}>
                    <p>Замовелння #{order.id}</p>
                    <p>
                        Клієнт: {clientInfo.clientName} {clientInfo.clientPhone}
                    </p>
                </div>
                <div className={classes.modal__row}>
                    <p>Пристрій: {deviceInfo.deviceType}</p>
                    <p>Виробник: {deviceInfo.deviceProducer}</p>
                    <p>Модель: {deviceInfo.deviceModel}</p>
                </div>
                <div className={classes.modal__row}>
                    <p>Собівартість: {repairCost}</p>
                    <p>Ціна: {repairPrice}</p>
                    <p>Заробіток: {repairIncome}</p>
                </div>
                <div className={classes.modal__submit}>
                    <Button color="blue" onClick={handleSubmit}>
                        Завершити
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FinishOrderModal;
