const AccountIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="_x32_" viewBox="0 0 512 512" {...props}>
        <path
            d="M364.032 355.035c-3.862-1.446-8.072-3.436-12.35-5.794l-71.57 98.935-5.09-64.814h-38.033l-5.091 64.814-71.569-98.935c-4.408 2.466-8.656 4.487-12.361 5.794-37.478 13.193-129.549 51.136-123.607 122.21C25.787 494.301 119.582 512 256.006 512c136.413 0 230.208-17.699 231.634-34.755 5.943-71.143-86.367-108.284-123.608-122.21z"
            className="st0"
        />
        <path
            d="M171.501 208.271c5.21 29.516 13.966 55.554 25.494 68.38v35.587c0 .902-.158 1.852-.416 2.833l40.41 19.462v28.545h38.033v-28.545l40.39-19.452c-.258-.981-.416-1.932-.416-2.843v-35.587c11.548-12.826 20.304-38.864 25.514-68.38 12.143-4.338 19.096-11.281 27.762-41.658 9.231-32.358-13.876-31.258-13.876-31.258 18.69-61.873-5.922-120.022-47.124-115.753-28.426-49.73-123.627 11.36-153.48 7.102 0 17.055 7.112 29.842 7.112 29.842-10.379 19.69-6.378 58.951-3.446 78.809-1.704-.03-22.602.188-13.728 31.258 8.675 30.377 15.608 37.321 27.771 41.658z"
            className="st0"
        />
    </svg>
);
export default AccountIcon;
